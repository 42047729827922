// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export const columnSettings = {
  [GC.FIELD_USER_LOGIN_ID]: {
    width: 150,
    name: 'titles:user-id',
  },
  [GC.FIELD_BRANCH_NAME]: {
    width: 250,
    name: 'titles:branch',
  },
  [GC.FIELD_USER_TYPE]: {
    width: 150,
    type: 'enum',
    name: 'titles:type',
  },
  [GC.FIELD_USER_FIRST_NAME]: {
    width: 150,
    name: 'titles:first-name',
  },
  [GC.FIELD_USER_LAST_NAME]: {
    width: 150,
    name: 'titles:last-name',
  },
  [GC.FIELD_USER_EMAIL]: {
    width: 200,
    name: 'titles:email',
  },
  [`${GC.SYSTEM_OBJECT_ROLES}.${GC.FIELD_ROLE_NAME}`]: {
    width: 300,
    collection: true,
    name: 'titles:roles',
  },
  [GC.GRC.DISPATCHING_GROUPS_NAME]: {
    width: 300,
    collection: true,
    name: 'titles:dispatching-group',
  },
  [GC.FIELD_USER_FAX_NUMBER]: {
    width: 150,
    name: 'titles:fax-number',
  },
  [GC.FIELD_USER_PHONE_NUMBER]: {
    width: 150,
    name: 'titles:phone-number',
  },
  [GC.FIELD_USER_SMS_SENDER_NAME]: {
    width: 150,
    name: 'titles:sms-sender-name',
  },
  [GC.FIELD_USER_LOCALE]: {
    width: 150,
    name: 'titles:locale',
  },
  [GC.FIELD_ACTIVE]: {
    width: 200,
    type: 'boolean',
    name: 'titles:active',
  },
  [GC.FIELD_LAST_LOGIN_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:last-login-date',
  },
  default: {
    width: 300,
    name: 'Ref: ',
  },
};

export const tableSettings = {
  minHeight: 320,
  cellFontSize: 13,
  titleFontSize: 14,
  titleRowHeight: 45,
  tableRowHeight: 40,
  allowEditBtn: true,
  useMainColors: true,
  moreBtnCellWidth: 50,
  allowSelectAll: true,
  checkBoxCellWidth: 90,
  expandableItems: false,
  searchableTitles: true,
  maxHeight: 'calc(100vh - 145px)',
  checkBoxCellJustifyContent: 'unset',
};
